<template>
  <div class="app-container">
    <!-- 顶部按钮 -->
    <div class="top_btns clearfix">
      <div class="fl">
        <el-date-picker @change="getList" v-model="listQuery.period" value-format="YYYYMM" format="YYYY-MM" type="month" placeholder="选择月" size="small"></el-date-picker>
      </div>
      <div class="right_btns fr">
        <qzf-video vid="4467be53741d6cbdf4348ffe36ff5130_4"></qzf-video>

        <!-- <el-button  @click="handleCreate" size="small" type="success">添加</el-button> -->
        <el-dropdown split-button type="success" @click="handleCreate" size="small" style="margin-right:10px" :hide-on-click="false">
          <i class="iconfont icon-jiahao"></i> 添加
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item>
                <span size="small" @click="openCom">导入</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <qzf-button jz="false" button_code="xc_ljsj_sc" type="danger" size="small" @success='delallClick'   >
          <el-icon><Delete /></el-icon> <span  >批量删除</span>
        </qzf-button>
      </div>
    </div>
    <!-- 顶部按钮 end -->
    <!-- 内容 -->
    <el-table stripe :height="contentStyleObj" @selection-change="handleSelectionChange" v-loading="listLoading" :header-cell-style="{background: '#eee',color:'#333','font-size': '13px'}" :data="tableData" border style="width: 100%" show-summary>
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column align="center" type="selection" width="55"></el-table-column>
      <el-table-column type="index" fixed label="序号" align="center" width="60"></el-table-column>
      <el-table-column label="姓名"  min-width="100" fixed>
        <template #default="{row}">
          {{row.name}}
        </template>
      </el-table-column>
      <el-table-column label="证件号码" min-width="200" fixed>
        <template #default="{row}">
          {{row.idNo}}
        </template>
      </el-table-column>
       <el-table-column label="累计收入" prop="salary" min-width="100">
        <template #default="{row}">
          {{row.salary}}
        </template>
      </el-table-column>

      <el-table-column label="累计免税收入" prop="ljmssr" min-width="120">
        <template #default="{row}">
          {{row.ljmssr}}
        </template>
      </el-table-column>

      <el-table-column label="累计减除费用" prop="deductOther" min-width="120">
        <template #default="{row}">
          {{row.deductOther}}
        </template>
      </el-table-column>
      <el-table-column label="累计专项扣除" prop="deductSocial" min-width="120">
        <template #default="{row}">
          {{row.deductSocial}}
        </template>
      </el-table-column>
      <el-table-column label="累计其他扣除" min-width="120">
        <template #default="{row}">
          {{row.qtkc}}
        </template>
      </el-table-column>
      <el-table-column label="子女教育" prop="childrenEducation" min-width="100">
        <template #default="{row}">
          {{row.childrenEducation}}
        </template>
      </el-table-column>
      <el-table-column label="继续教育" prop="continuingEducation" min-width="100">
        <template #default="{row}">
          {{row.continuingEducation}}
        </template>
      </el-table-column>
      <el-table-column label="大病医疗" prop="seriousIllness" min-width="100">
        <template #default="{row}">
          {{row.seriousIllness}}
        </template>
      </el-table-column>
      <el-table-column label="住房贷款利息" prop="housingLoan" min-width="100">
        <template #default="{row}">
          {{row.housingLoan}}
        </template>
      </el-table-column>
      <el-table-column label="住房租金" prop="housingRent" min-width="100">
        <template #default="{row}">
         {{row.housingRent}}
        </template>
      </el-table-column>
      <el-table-column label="赡养老人" prop="support" min-width="100">
        <template #default="{row}">
          {{row.support}}
        </template>
      </el-table-column>
      <el-table-column label="婴幼儿照护费用" prop="babyReduction" min-width="120">
        <template #default="{row}">
          {{row.babyReduction}}
        </template>
      </el-table-column>
      <el-table-column label="累计应纳所得额" prop="amount" min-width="120">
        <template #default="{row}">
          {{row.amount}}
        </template>
      </el-table-column>
      <el-table-column label="累计应交税款" prop="tax" min-width="120">
        <template #default="{row}">
          {{row.tax}}
        </template>
      </el-table-column>
      <el-table-column label="累计已交税款" prop="taxEnd" min-width="120">
        <template #default="{row}">
          {{row.taxEnd}}
        </template>
      </el-table-column>
    </el-table>
    <!-- 内容 end -->

    <div class="bottom_style">
      <div class="bottom_button">
      </div>
      <div class="pagination">
        <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" />
      </div>
    </div>
    
    <qzf-import-back :hideButton="true" ref="importBck" @success="getList()" from="税款计算" :comId="this.listQuery.comId" :liPeriod="this.listQuery.period"></qzf-import-back>
    <addInformation ref="addInformation" @success="getList" :period="this.listQuery.period" :comIdInit="this.listQuery.comId"></addInformation>
  </div>
</template>

<script>
import { parseTime } from '@/utils'
import addInformation from './addInformation.vue'
import { getEaSalayPeriod,salaryInitDel } from '@/api/salary.js'
export default {
  name:'information',
  components:{ addInformation },
  watch: {
    $route(to, from){
      if(to.name == "information"){
        this.getList()
      }
    }
  },
  destroyed(){
    
  },
  data() {
    return{
      tableData: [],
      listQuery: {
        period: '',
        comId: 0,
        periodf: '',
      },
      delall:[],
      listLoading: true,
    }
  },
  created() {
    // 获取表格高度
    this.contentStyleObj=this.$getHeight(230)
    window.addEventListener('resize', this.getHeight);
    this.getList()
  },
  methods: {
    handleCreate() {
      // if(this.$checkSettleStatus() != ""){
      //   this.$message.error(this.$checkSettleStatus())
      //   return 
      // }
      this.$refs.addInformation.openSalary()
    },
    handleSelectionChange(e){
      this.delall = e
    },
    //批量删除
    delallClick(){
      if(this.$checkSettleStatus() != ""){
        this.$message.error(this.$checkSettleStatus())
        return 
      }
      if(this.delall.length == 0){
          this.$qzfMessage("请至少选择一个",1)
        }else{
          this.$confirm("确定删除选项吗？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }).then(() => {
            let idss=[]
            this.delall.map(item=>{
              idss.push({id:item.id})
            })
            salaryInitDel(idss).then(res=>{
              if(res.data.msg == "success"){
                this.$message.success('删除成功')
              }else{
                this.$message.error('删除失败')
              }
              this.getList()
            })
          }); 
        }
    },
    //列表展示
    getList() {
      if(this.$store.getters["commons/params"].informationPeriod){
        this.listQuery.period = this.$store.getters["commons/params"].informationPeriod
        this.listQuery.comId = this.$store.getters["commons/params"].informationComId
      }else if(this.listQuery.period){
        this.listQuery.period
      }else{
        this.listQuery.comId = this.$store.getters['user/comInfo'].comId*1
        this.listQuery.period =  this.$store.getters['user/comInfo'].period
      }
      if(this.listQuery.period == ""){
        return
      }
      this.listLoading = true
      getEaSalayPeriod(this.listQuery).then(res => {
        this.listLoading = false
        if(res.data.data.list) {
          this.tableData = res.data.data.list
        }else {
          this.tableData = []
        }
      })
    },
    openCom(){
      this.$refs.importBck.handImport()
    }
  }
}
</script>

<style lang="scss" scoped>
 
.el-table {
  margin-top: 10px;
}
</style>