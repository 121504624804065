<template>
  <el-dialog :close-on-click-modal="false" destroy-on-close title="新增" v-model="dialogFormVisible" class="clearfix button_bg" >
      <el-form style="max-height:380px;overflow:auto;" ref="dataForm" :rules="rules" :model="temp" label-position="right" label-width="120px" class="clearfix">
        <div class="common_dialog_box clearfix">
          <div class="item_one clearfix">
            <el-form-item label="账期:" class="item" prop="period">
              <el-date-picker @change="changePeiod" v-model="temp.period" type="month" placeholder="选择账期" size="small" style="width:150px"></el-date-picker>
              （默认上一账期）
            </el-form-item>
            <el-form-item label="身份证号:" prop="idNo" class="item">
              <el-input v-model="temp.idNo" style="width:90%;" size="small"/>
            </el-form-item>
            <el-form-item label="累计收入:" prop="salary" class="item">
              <qzf-input v-model:num="temp.salary" style="width:90%;" size="small"/>
            </el-form-item>
            <el-form-item label="累计免税收入:" prop="ljmssr" class="item">
              <qzf-input v-model:num="temp.ljmssr" style="width:90%;" size="small"/>
            </el-form-item>
             <el-form-item label="累计减除费用:" prop="deductOther" class="item">
              <qzf-input v-model:num="temp.deductOther" style="width:90%;" size="small"/>
            </el-form-item>
            <el-form-item label="累计专项扣除:" prop="deductSocial" class="item">
              <qzf-input v-model:num="temp.deductSocial" style="width:90%;" size="small"/>
            </el-form-item>
            <el-form-item label="累计其他扣除:" class="item">
              <qzf-input v-model:num="temp.qtkc" style="width:90%;" size="small"/>
            </el-form-item>
            <el-form-item label="子女教育:" prop="childrenEducation" class="item">
              <qzf-input v-model:num="temp.childrenEducation" style="width:90%;" size="small"/>
            </el-form-item>

            <el-form-item label="继续教育:" prop="continuingEducation" class="item">
              <qzf-input v-model:num="temp.continuingEducation" style="width:90%;" size="small"/>
            </el-form-item>

            <el-form-item label="大病医疗:" prop="seriousIllness" class="item">
              <qzf-input v-model:num="temp.seriousIllness" style="width:90%;" size="small"/>
            </el-form-item>

            <el-form-item label="住房贷款:" prop="housingLoan" class="item">
              <qzf-input v-model:num="temp.housingLoan" style="width:90%;" size="small"/>
            </el-form-item>

            <el-form-item label="住房租金:" prop="housingRent" class="item">
              <qzf-input v-model:num="temp.housingRent" style="width:90%;" size="small"/>
            </el-form-item>

            <el-form-item label="赡养老人:" prop="support" class="item">
              <qzf-input v-model:num="temp.support" style="width:90%;" size="small"/>
            </el-form-item>
            <el-form-item label="婴幼儿照护费用:" prop="babyReduction" class="item">
              <qzf-input v-model:num="temp.babyReduction" style="width:90%;" size="small"/>
            </el-form-item>
            <el-form-item label="累计应纳所得额:" prop="amount" class="item">
              <qzf-input v-model:num="temp.amount" style="width:90%;" size="small"/>
            </el-form-item>
            <el-form-item label="累计应交税款:" prop="tax" class="item">
              <qzf-input v-model:num="temp.tax" style="width:90%;" size="small"/>
            </el-form-item>
            <el-form-item label="累计已交税款:" prop="taxEnd" class="item">
              <qzf-input v-model:num="temp.taxEnd" style="width:90%;" size="small"/>
            </el-form-item>
          </div>
        </div>
      </el-form>
      <div class="dialog-footer">
        <el-button @click="dialogFormVisible = false" size="small">取消</el-button>
        <el-button @click="createData()" type="primary" size="small">确定</el-button>
        <!-- <qzf-button jz="false" button_code="ljsj_xzqd" type="primary" @click="createData()" size="small">确定</qzf-button> -->
      </div>
    </el-dialog>
</template>

<script>
import { parseTime } from '@/utils'
import { salaryInitImport } from '@/api/salary.js'
export default {
  name: 'addInformation',
  props: {
    period: {
      type: String,
      default: "",
    },
    comIdInit: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      list: [],
      dialogFormVisible: false,
      temp: {
        period:this.period,
        // periodf:undefined,
        idNo: '',
        salary: undefined,
        ljmssr: undefined,
        deductSocial:undefined,
        deductOther:undefined,
        childrenEducation: undefined,
        continuingEducation: undefined,
        seriousIllness: undefined,
        housingLoan: undefined,
        housingRent: undefined,
        support:undefined,
        amount:undefined,
        babyReduction: undefined,
        tax:undefined,
        taxEnd:undefined,
        qtkc:undefined
      },
      rules: {
        period: [{ required: true, message: '请选择账期', trigger: 'change' }],
        idNo: [{ required: true, message: '请输入身份证号', trigger: 'change' }],
        salary: [{ required: true, message: '请输入累计收入', trigger: 'blur' }],
        ljmssr: [{ required: false, message: '请输入累计免税收入', trigger: 'blur' }],
        deductOther: [{ required: false, message: '请输入累计减除费用', trigger: 'blur' }],
        deductSocial: [{ required: false, message: '请输入累计专项扣除', trigger: 'blur' }],
        childrenEducation: [{ required: false, message: '请输入子女教育金额', trigger: 'blur' }],
        continuingEducation: [{ required: false, message: '请输入继续教育金额', trigger: 'blur' }],
        seriousIllness: [{ required: false, message: '请输入大病医疗金额', trigger: 'blur' }],
        housingLoan: [{ required: false, message: '请输入住房贷款金额', trigger: 'blur' }],
        housingRent: [{ required: false, message: '请输入住房租金金额', trigger: 'blur' }],
        support: [{ required: false, message: '请输入赡养老人金额', trigger: 'blur' }],
        babyReduction: [{ required: false, message: '请输入累计婴幼儿照护费用', trigger: 'blur' }],
        amount: [{ required: false, message: '请输入累计应纳所得额', trigger: 'blur' }],
        tax: [{ required: false, message: '请输入累计应交税款', trigger: 'blur' }],
        taxEnd: [{ required: false, message: '请输入累计已交税款', trigger: 'blur' }]
      },
      delall: [],
      contentStyleObj:{}, //高度变化
      comId:0
    };
  },
  created(){
    console.log(this.comIdInit);
    if(this.comIdInit){
      this.comId = this.comIdInit
    }else{
      this.comId = this.$store.getters['user/comInfo'].comId*1
    }
  },
  methods: {
    //弹窗
    openSalary(){
      this.dialogFormVisible = true
    },
    changePeiod(){
      if(this.temp.periodf){
        this.temp.period = parseTime(this.temp.periodf, "{y}{m}");
      }
    },
    resetTemp() {
      this.temp = {
        period:this.period,
        idNo: this.temp.idNo,
        salary: Number(this.temp.salary),
        ljmssr: Number(this.temp.ljmssr),
        deductSocial:Number(this.temp.deductSocial),
        deductOther:Number(this.temp.deductOther),
        childrenEducation: Number(this.temp.childrenEducation),
        continuingEducation: Number(this.temp.continuingEducation),
        seriousIllness: Number(this.temp.seriousIllness),
        housingLoan: Number(this.temp.housingLoan),
        housingRent: Number(this.temp.housingRent),
        support:Number(this.temp.support),
        amount:Number(this.temp.amount),
        babyReduction: Number(this.temp.babyReduction),
        tax:Number(this.temp.tax),
        taxEnd:Number(this.temp.taxEnd),
        qtkc:Number(this.temp.qtkc),
      }
    },
    createData() {
      this.$refs['dataForm'].validate((valid) => {
        if(valid) {
          this.resetTemp()
          let param = {
            items:[this.temp],
            period : this.temp.period,
            comId: this.comId,
          }
          salaryInitImport(param).then(res => {
            if(res.data.msg == "success"){
              this.$message({
                message: '新增成功',
                type: 'success'
              });
              this.dialogFormVisible = false
              this.$emit("success")
            }
          })
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.item_one {
  display: flex;
  flex-wrap: wrap;
}
</style>